import { FC } from "react";
import PageTitle from "../PageTitle";
import "./../css/global.css";
import "./../css/text.css";
import Spacing from "./Spacing";
import ConnectIconItem from "../ConnectIconItem";

const ConnectPage: FC = () => {
  return (
    <div id="connect" className="page">
      <PageTitle title="connect" />
      <div className="flex-column align-center" style={{ height: "100%" }}>
        <div>
          <Spacing height={20} />
          <div className="flex-column align-center">
            <div style={{textAlign: "center"}}>
              <img
                src="./profile_small.jpg"
                alt="Nicholas Randall"
                style={{ height: 364, width: 260, border: "#898989 solid 7px", borderRadius: 4 }}
              />
              <h1>NICK RANDALL</h1>
              <Spacing height={20} />
              <div className="flex-row space-between align-center">
                <ConnectIconItem name="send_blue" tooltipText="randall@posteo.de" height={20} />
                <ConnectIconItem name="mobile_blue" tooltipText="0493 660 180" height={20} />
                <ConnectIconItem name="home_blue" tooltipText="56 Valley Drive, Caboolture 4510" height={20} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConnectPage;
