import { FC } from "react";

interface SpacingProps {
  height?: number | string;
  width?: number | string;
}

const Spacing: FC<SpacingProps> = ({ height, width }) => <div style={{ height: height, width: width }} />;

export default Spacing;
