import { FC, useCallback, useContext, useEffect, useRef, useState } from "react";
import { MockLayoutContext } from "./AnimationValuesProvider";

interface LiftedContainerProps {
  children: JSX.Element;
}

interface Offset {
  dx: number;
  dy: number;
}

const LiftedContainer: FC<LiftedContainerProps> = ({ children }) => {
  const { mockAnimationValues, allValuesReady, handleLiftIterationEnd, handleDragLiftedContainer } = useContext(MockLayoutContext);
  const [initialised, setInitialised] = useState(false);
  const [draggedLoc, setDraggedLoc] = useState<Offset>();
  const ref = useRef<HTMLDivElement>(null);

  const handleDragStart = useCallback(() => {
    const rect = ref?.current?.getBoundingClientRect();
    if (rect) {
      const loc: Offset = { dx: rect.x, dy: rect.y };
      setDraggedLoc(loc);
    }
  }, [setDraggedLoc]);

  const handleDragEnd = useCallback(() => {
    setDraggedLoc(undefined);
  }, [setDraggedLoc]);

  const handleDrag = useCallback(() => {
    if (!draggedLoc) return;

    const rect = ref?.current?.getBoundingClientRect();
    if (rect) {
      const loc: Offset = { dx: rect.x, dy: rect.y };
      setDraggedLoc(loc);
    }
    handleDragLiftedContainer?.(draggedLoc);
  }, [draggedLoc, handleDragLiftedContainer]);

  useEffect(() => {
    window.addEventListener("mousemove", handleDrag);
    window.addEventListener("mousedown", handleDragStart);
    window.addEventListener("mouseup", handleDragEnd);

    return () => {
      window.removeEventListener("mousemove", handleDrag);
      window.removeEventListener("mousedown", handleDragStart);
      window.removeEventListener("mouseup", handleDragEnd);
    };
  }, [handleDrag, handleDragEnd, handleDragStart]);

  if (allValuesReady) {
    setTimeout(() => setInitialised(true), 1);

    const currAnimationValues = mockAnimationValues[0];
    const ex = initialised ? 0 : 1;
    return (
      <div
        ref={ref}
        className="top-container"
        style={{
          transition: initialised ? `${currAnimationValues.animationDuration}s ease-out` : "",
          transform: `translateY(calc(-98% + ${currAnimationValues.dy + ex}px))`,
          position: "relative",
        }}
        onTransitionEnd={handleLiftIterationEnd}
      >
        {children}
      </div>
    );
  }
  return <div></div>;
};

export default LiftedContainer;
