import { FC, useRef, useState } from "react";
import "./css/menu.css";

interface IconItemProps {
  name: string;
  hidden?: boolean;
}

const IconItem: FC<IconItemProps> = ({ name, hidden }) => {
  const handleClick = () => console.log(name);
  const [showTooltip, setShowTooltip] = useState(false);

  const ref = useRef<HTMLImageElement>(null);

  return (
    <div style={{ paddingLeft: "1rem", paddingRight: "1rem" }} onClick={handleClick}>
      <a href={`#${name}`}>
        <img
          src={`./${name}.png`}
          className={`menu-icon ${hidden ? "hidden" : ""}`}
          alt={name}
          onMouseEnter={() => setShowTooltip(true)}
          onMouseLeave={() => setShowTooltip(false)}
          ref={ref}
        />

        <Tooltip name={name} showTooltip={showTooltip} />
      </a>
    </div>
  );
};

interface TooltipProps {
  showTooltip: boolean;
  name: string;
}

const Tooltip: FC<TooltipProps> = ({ showTooltip, name }) => {
  if (!showTooltip) return <div></div>;
  return (
    <div style={{ position: "absolute" }}>
      <div style={{ left: "-2rem", position: "relative", backgroundColor: "grey", paddingLeft: "0.2rem", paddingRight: "0.2rem" }}>{name}</div>
    </div>
  );
};

export default IconItem;
