import { FC } from "react";
import useMediaQuery from "./useMediaQuery";
import Logo from "./Logo";
import Hamburger from "./Hamburger";
import IconTriple from "./IconTriple";

interface MenuRowProps {
  hidden?: boolean;
  onClickedHamburger: () => void;
}

const MenuRow: FC<MenuRowProps> = ({ hidden, onClickedHamburger }) => {
  const { deviceType } = useMediaQuery();
  const paddingLeft = deviceType === "laptop" ? "1rem" : "";

  return (
    <div className="flex-row space-between inherit-width align-items">
      <div style={{ paddingLeft: paddingLeft }}>
        <Logo hidden={hidden} />
      </div>
      {deviceType === "phone" && (
        <div style={{ paddingRight: 5 }}>
          <Hamburger hidden={hidden} onPressed={onClickedHamburger} />
        </div>
      )}
      {deviceType !== "phone" && <IconTriple hidden={hidden} />}
    </div>
  );
};

export default MenuRow;
