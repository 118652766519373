import { createContext, useState } from "react";
import initialBellowsAnimationValues from "./generateBounceAnimationValues";

export type AnimationProviderProps = {
  mockAnimationValues: MockAnimationValues[];
  bellowsAnimationValues: LiftAnimationProps[];
  updateMockAnimationValue: (value: MockAnimationValues) => void;
  handleLiftIterationEnd: (() => void) | undefined;
  handleBellowsIterationEnd: (() => void) | undefined;
  allValuesReady: boolean;
  nextLiftValue?: MockAnimationValues;
  handleDragLiftedContainer: ((offset: Offset) => void) | undefined;
};

const createMockAnimationValues = (bellowsAnimationValues: LiftAnimationProps[]): MockAnimationValues[] =>
  bellowsAnimationValues.map(value => ({ id: value.id, animationDuration: value.animationDuration, dy: 0, status: "notReady" }));

export const MockLayoutContext = createContext<AnimationProviderProps>({
  mockAnimationValues: createMockAnimationValues(initialBellowsAnimationValues),
  bellowsAnimationValues: initialBellowsAnimationValues,
  updateMockAnimationValue: (v: MockAnimationValues) => {
    console.log("not initialized yet");
  },
  handleLiftIterationEnd: undefined,
  handleBellowsIterationEnd: undefined,
  handleDragLiftedContainer: undefined,
  allValuesReady: false,
});

const MockLayoutProvider = ({ children }: { children: JSX.Element }) => {
  const [mockAnimationValues, setMockAnimationValues] = useState(createMockAnimationValues(initialBellowsAnimationValues));
  const [bellowsAnimationValues, setBellowsAnimationValues] = useState(initialBellowsAnimationValues);
  const [nextLiftValue, setNextLiftValue] = useState(mockAnimationValues.find(animationValue => animationValue.status === "notReady"));
  const [allValuesReady, setAllValuesReady] = useState(false);

  const updateMockAnimationValue = (newValue: MockAnimationValues) => {
    const updated = mockAnimationValues.map(value => (newValue.id === value.id ? newValue : value));
    setMockAnimationValues(updated);
    setNextLiftValue(mockAnimationValues.find(animationValue => animationValue.status === "notReady"));
    checkIfAllValuesReady();
  };

  const checkIfAllValuesReady = () => {
    const isAllReady = mockAnimationValues.every(value => value.status === "ready");
    setAllValuesReady(isAllReady);
  };

  const handleLiftIterationEnd = () => {
    if (mockAnimationValues.length !== 1) {
      setMockAnimationValues(mockAnimationValues.slice(1));
    }
  };

  const handleBellowsIterationEnd = () => {
    if (bellowsAnimationValues.length !== 1) {
      setBellowsAnimationValues(bellowsAnimationValues.slice(1));
    }
  };
  const handleDragLiftedContainer = (offset: Offset) => console.log(offset)

  const providerValues: AnimationProviderProps = {
    bellowsAnimationValues,
    mockAnimationValues,
    updateMockAnimationValue,
    handleBellowsIterationEnd,
    handleLiftIterationEnd,
    allValuesReady,
    nextLiftValue,
    handleDragLiftedContainer,
  };
  return <MockLayoutContext.Provider value={providerValues}>{children} </MockLayoutContext.Provider>;
};

export default MockLayoutProvider;
