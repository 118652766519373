import { FC } from "react";
import MenuItem from "./MenuItem";

interface MenuPopupProps {
  hidden: boolean;
  width: number;
  onMenuItemClicked: () => void;
}

const MenuPopup: FC<MenuPopupProps> = ({ hidden, width, onMenuItemClicked }) => {
  return (
    <div className={`menu-popup ${hidden ? "hidden" : ""}`} hidden={hidden} style={{ width: width, display: hidden ? "none" : "" }}>
      <MenuItem name="frameworks" hidden={hidden} handleMenuItemClicked={onMenuItemClicked} />
      <MenuItem name="projects" hidden={hidden} handleMenuItemClicked={onMenuItemClicked} />
      <MenuItem name="connect" hidden={hidden} handleMenuItemClicked={onMenuItemClicked} />
    </div>
  );
};

export default MenuPopup;
