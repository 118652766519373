import { FC, useRef, useState } from "react";
import "./css/menu.css";

interface ConnectIconItemProps {
  name: string;
  tooltipText: string;
  height: number;
  hidden?: boolean;
}

const ConnectIconItem: FC<ConnectIconItemProps> = ({ name, hidden, tooltipText, height }) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const [showCopied, setShowCopied] = useState(false);

  const ref = useRef<HTMLImageElement>(null);

  const copyToClipboard = async () => {
    await navigator.clipboard.writeText(tooltipText);
    setShowCopied(true);
  };

  return (
    <div style={{ paddingLeft: "1rem", paddingRight: "1rem", cursor: "pointer" }}>
      <img
        style={{ color: "black" }}
        src={`./${name}.png`}
        className={`menu-icon ${hidden ? "hidden" : ""}`}
        alt={name}
        onMouseEnter={() => setShowTooltip(true)}
        onMouseLeave={() => {
          setShowTooltip(false);
          setShowCopied(false);
        }}
        onClick={copyToClipboard}
        ref={ref}
      />

      <Tooltip text={tooltipText} showTooltip={showTooltip} showCopied={showCopied} />
    </div>
  );
};

interface TooltipProps {
  showTooltip: boolean;
  text: string;
  showCopied: boolean;
}

const Tooltip: FC<TooltipProps> = ({ showTooltip, text, showCopied }) => {
  if (!showTooltip) return <div></div>;
  return (
    <div style={{ position: "absolute" }}>
      <div
        style={{
          left: "-4rem",
          position: "relative",
          backgroundColor: "#00aacc",
          paddingLeft: "0.2rem",
          paddingRight: "0.2rem",
          maxWidth: "12rem",
          textAlign: "center",
          // display: "flex",
        }}
      >
        {text}
        {showCopied && <div>Copied!</div>}
      </div>
    </div>
  );
};

export default ConnectIconItem;
