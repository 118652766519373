import { FC } from "react";
import "./../css/global.css";
import "./../css/text.css";

import PageTitle from "../PageTitle";
import Spacing from "./Spacing";
import { Flex } from "../util_components/Flex";
import useMediaQuery from "../useMediaQuery";

const FrameworksPage: FC = () => {
  const { deviceType } = useMediaQuery();
  return (
    <div id="frameworks" className="page">
      <PageTitle title="frameworks" />
      <Flex alignItems="center" minHeight={"100%"}>
        <Spacing height={20} />
        <Flex flexDirection="row" alignItems="center">
          <img src="./flutter-logo.png" alt="Flutter logo" style={{ height: 60 }} />
          <Spacing width={20} />
          <h2>flutter</h2>
        </Flex>
        <p>
          I've been creating Flutter mobile apps since 2019. I’ve worked both collaboratively as part of agile teams, and independently in roles with
          full responsibility for the entire development cycle.
        </p>
        <p>
          This range of experience has given me the chance to experiment, and to know intimately how the Flutter framework can be leveraged to build
          stunning and performative apps with clear and understandable code.
        </p>
        <Flex flexDirection="row" alignItems="center">
          <img src="./react-logo.png" alt="React.js logo" style={{ height: 70 }} />
          <Spacing width={20} />
          <h2>react.js</h2>
        </Flex>
        <p>
          Over years of building and implementing personal projects in this framework, I've developed high proficiency in developing and coding
          React.js projects. This process of building skills has been complemented by the skills I've acquired in my main framework, Flutter, whose
          reactive concepts overlap signifcantly with it.
        </p>
        <Spacing height={20} />
        <p>additional frameworks:</p>
        <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "center", gap: deviceType === "laptop" ? 30 : 15 }}>
          <div>
            <img src="./swift_logo.svg" alt="Swift logo" style={{ height: 60 }} /> <h2>swift</h2>
          </div>
          <div>
            <img src="./spring_logo.png" alt="Spring logo" style={{ height: 60 }} /> <h2>spring</h2>
          </div>
          <div>
            <img src="./hasura_logo.svg" alt="Hasura logo" style={{ height: 60 }} /> <h2>hasura</h2>
          </div>
          <div>
            <img src="./firebase_logo.png" alt="Firebase logo" style={{ height: 60 }} /> <h2>firebase</h2>
          </div>
          <div>
            <img src="./git_logo.png" alt="Git logo" style={{ height: 60 }} /> <h2>git</h2>
          </div>
          {/* <div>
            <img src="./salesforce_logo.png" alt="Salesforce logo" style={{ height: 60 }} /> <h2>SalesForce</h2>
          </div> */}
          <div>
            <img src="https://nodejs.org/static/logos/jsIconGreen.svg" alt="node.js logo" style={{ height: 60 }} />
            <h2>node.js</h2>
          </div>
          <div>
            <img
              src="https://s3.dualstack.us-east-2.amazonaws.com/pythondotorg-assets/media/community/logos/python-logo-only.png"
              alt="node.js logo"
              style={{ height: 60 }}
            />
            <h2>python</h2>
          </div>
          <div>
            <img src="./jira_logo.png" alt="Jira logo" style={{ height: 60 }} /> <h2>jira</h2>
          </div>
          <div>
            <img src="https://wiki.postgresql.org/images/3/30/PostgreSQL_logo.3colors.120x120.png" alt="Jira logo" style={{ height: 60 }} />
            <h2>postgresql</h2>
          </div>  
        </div>
      </Flex>
    </div>
  );
};

export default FrameworksPage;
