import { useCallback, useEffect, useState } from "react";

const useMediaQuery = () => {
  const [deviceType, setDeviceType] = useState<Device>("phone");
  const [screenHeight, setScreenHeight] = useState(0);
  const detectDeviceType = useCallback(() => {
    const screenWidth = window.outerWidth;
    setScreenHeight(window.outerHeight);
    if (screenWidth <= 680) {
      setDeviceType("phone");
    } else if (screenWidth > 480 && screenWidth <= 960) {
      setDeviceType("tablet");
    } else {
      setDeviceType("laptop");
    }
  }, []);

  useEffect(() => {
    detectDeviceType();
    window.addEventListener("resize", detectDeviceType);
    // return window.removeEventListener("resize", detectDeviceType);
  });
  return { deviceType, screenHeight };
};

export default useMediaQuery;
