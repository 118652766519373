import { Property } from "csstype";
import { CSSProperties, FC } from "react";

interface FlexProps {
  className?: string;
  // container?: boolean;
  children: JSX.Element[] | JSX.Element;
  justifyContent?: Property.JustifyContent;
  flexDirection?: Property.FlexDirection;
  flexGrow?: Property.FlexGrow;
  flexBasis?: Property.FlexBasis;
  flexShrink?: Property.FlexShrink;
  flexWrap?: Property.FlexWrap;
  flex?: Property.Flex;
  alignItems?: Property.AlignItems;
  margin?: Property.Margin;
  padding?: Property.Padding;
  width?: Property.Width;
  height?: Property.Height;
  maxWidth?: Property.MaxWidth;
  minHeight?: Property.MinHeight;
  style?: CSSProperties;
}

export const Flex: FC<FlexProps> = (props: FlexProps) => (
  <div
    className={props.className}
    style={{
      display: "flex",
      justifyContent: props.justifyContent || "flex-start",
      flexDirection: props.flexDirection || "column",
      flexGrow: props.flexGrow || 0,
      flexBasis: props.flexBasis || "auto",
      flexShrink: props.flexShrink || 1,
      flexWrap: props.flexWrap || "nowrap",
      flex: props.flex || "0 1 auto",
      alignItems: props.alignItems || "stretch",
      margin: props.margin || "0",
      padding: props.padding || "0",
      width: props.width || "auto",
      height: props.height || "auto",
      maxWidth: props.maxWidth || "none",
      minHeight: props.minHeight || undefined,
      ...props.style,
    }}
  >
    {props.children}
  </div>
);
