import { FC } from "react";
import IconItem from "./IconItem";

interface IconTripleProps {
  handleMenuItemClicked?: (event: React.MouseEvent) => void;
  hidden?: boolean;
}

const IconTriple: FC<IconTripleProps> = ({ handleMenuItemClicked, hidden }) => {
  return (
    <div className="flex-row">
      <IconItem name="frameworks" hidden={hidden} />
      <IconItem name="projects" hidden={hidden} />
      <IconItem name="connect" hidden={hidden} />
    </div>
  );
};

export default IconTriple;
